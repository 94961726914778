<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ofertas Subastas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Comercio</li>
                  <li class="breadcrumb-item active">Gestión Subastas</li>
                  <li class="breadcrumb-item active">Ofertas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div
                    class="col-md-1"
                    v-if="$store.getters.can('com.ofertaSubastas.index')"
                  >
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button type="button" class="btn btn-success">
                        <i class="far fa-file-excel fa-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">Fecha Maxima Oferta</th>
                      <th class="text-center">Ofertado</th>
                      <th class="text-center" v-if="user.cliente">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ofertSubastas.data" :key="item.id">
                      <td class="text-center">{{ item.id }}</td>
                      <td class="text-center">{{ item.nombre }}</td>
                      <td class="text-center">{{ item.fecha_ini }}</td>
                      <td class="text-center">{{ item.fecha_fin }}</td>
                      <td class="text-center" v-if="user.cliente">
                        {{ item.fecha_max_oferta }} <br />
                        <span
                          class="badge badge-danger"
                          v-if="
                            ahora > item.fecha_max_oferta &&
                            item.ofertas.length == 0
                          "
                        >
                          Fecha Límite Excedida
                        </span>
                        <span
                          class="badge badge-info"
                          v-else-if="
                            ahora < item.fecha_max_oferta &&
                            item.ofertas.length == 0
                          "
                        >
                          {{ calcularTiempoRestante(item.fecha_max_oferta) }}
                        </span>
                        <span class="badge badge-primary" v-else>
                          {{ item.ofertas[0].nCliente }}
                        </span>
                      </td>
                      <td v-else>
                        {{ item.fecha_max_oferta }} <br />
                        <!-- <div v-if="item.clientes.length == item.ofertas.length">
                          <span class="badge badge-primary">
                            Todos Ofertaron
                          </span>
                        </div> -->
                      </td>
                      <td class="text-center" v-if="user.cliente">
                        <div>
                          <span
                            :class="
                              item.ofertas.length > 0 &&
                              item.ofertas[0].estado == 2
                                ? 'badge badge-primary p-1 mt-2'
                                : 'badge badge-danger p-1 mt-2'
                            "
                          >
                            <i
                              :class="
                                item.ofertas.length > 0 &&
                                item.ofertas[0].estado == 2
                                  ? 'fas fa-check-circle fa-lg text-white'
                                  : 'fas fa-times-circle fa-lg text-white'
                              "
                            ></i>
                          </span>
                        </div>
                        <span
                          :class="
                            item.ofertas.length > 0 &&
                            item.ofertas[0].estado == 1
                              ? 'badge badge-warning'
                              : ''
                          "
                        >
                          {{
                            item.ofertas.length && item.ofertas[0].estado == 1
                              ? "Oferta en BORRADOR"
                              : ""
                          }}
                        </span>
                      </td>
                      <td v-else>
                        <div v-if="item.ofertas.length > 0">
                          <div v-for="ofer in item.ofertas" :key="ofer.id">
                            <span
                              class="badge badge-light"
                            >
                              <button
                                type="button"
                                class="btn btn-xs bg-dark"
                                title="Ver Oferta"
                                @click="edit(item, 2, ofer.cliente_id)"
                              >
                                <i class="fas fa-eye fa-sm"></i>
                              </button>
                              {{ ofer.nCliente }}
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span class="badge badge-danger"
                            >No han Ofertado
                          </span>
                        </div>
                      </td>
                      <td class="text-center" v-if="user.cliente">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy mt-2"
                            :disabled="
                              ahora >= item.fecha_max_oferta ||
                              (item.ofertas.length > 0 &&
                                item.ofertas[0].estado == 2)
                            "
                            :title="
                              ahora >= item.fecha_max_oferta
                                ? 'Fecha Límite Excedida'
                                : 'Ofertar'
                            "
                            @click="edit(item, 1)"
                            v-if="user.cliente"
                          >
                            <i class="fas fa-comment-dollar fa-md"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-info mt-2"
                            :title="
                              ahora >= item.fecha_max_oferta
                                ? 'Fecha Límite Excedida'
                                : 'Ofertar'
                            "
                            @click="edit(item, 2)"
                            v-if="
                              user.cliente &&
                              $store.getters.can('com.ofertaSubastas.create')
                            "
                          >
                            <i class="fas fa-eye fa-md"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ofertSubastas.total">
                  <p>
                    Mostrando del <b>{{ ofertSubastas.from }}</b> al
                    <b>{{ ofertSubastas.to }}</b> de un total:
                    <b>{{ ofertSubastas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ofertSubastas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "OfertaSubastasIndex",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      cargando: false,
      ahora: moment().format("YYYY-MM-DD HH:mm:ss"),
      filtros: {
        n_identificacion: null,
        razon_social: null,
        representante: null,
        tel1: null,
      },
      ofertEnvio: {},
      user: this.$store.state.user,
      ofertSubastas: {},
      listasForms: {
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      //console.log(this.$store.state.user.cliente);
      let me = this;
      me.cargando = true;
      axios
        .get("/api/com/ofertaSubastas?page=" + page, {
          params: me.filtros,
        })
        .then((response) => {
          me.ofertSubastas = response.data;
          me.cargando = false;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    calcularTiempoRestante(fechaMaxOferta) {
      const fechaMaxOfertaObj = new Date(fechaMaxOferta);
      const ahora = new Date();
      const diferenciaEnMilisegundos = fechaMaxOfertaObj - ahora;
      const horasRestantes = Math.floor(
        diferenciaEnMilisegundos / (1000 * 60 * 60)
      );
      const minutosRestantes = Math.floor(
        (diferenciaEnMilisegundos / (1000 * 60)) % 60
      );

      return `${horasRestantes} horas y ${minutosRestantes} minutos`;
    },

    async edit(oferta, acto, cliente = null) {
      let me = this;
      me.cargando = true;
      let datos = { subasta: oferta.id, cliente: cliente };

      if (cliente !== null) {
        await axios
          .get("/api/com/ofertaSubastas/getOfertClient", {
            params: datos,
          })
          .then(async (response) => {
            me.ofertEnvio = await response.data;
            me.cargando = false;
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error:" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        me.ofertEnvio = await oferta;
      }

      return await this.$router.push({
        name: "/Com/OfertaSubastasForm",
        params: {
          accion: "Editar",
          data_edit: me.ofertEnvio,
          id: me.ofertEnvio.id,
          acto: acto,
        },
      });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
  